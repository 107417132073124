<template>
  <section id="openingHours" class="py-4">
    <div class="timer-list" style="margin-top: 0">
      <Icon
        name="clock-o"
        class="mr-2"
        :color="isHoliday('monday') ? 'red' : ''"
      />
      <span class="mr-2">{{
        $t("label.widget.modal.department.opening_hours.weekdays.monday")
      }}</span>
      <OpeningHoursLabel
        :openingHours="openingHours.monday"
        v-if="hasRanges('monday')"
        id="monday"
      />
      <!-- <FullDayLabel :openingHours="openingHours.monday" /> -->
      <DayClosedLabel v-if="isHoliday('monday')" />
    </div>

    <ElessarSlider
      id="monday"
      :values="openingHours.monday"
      @changeRange="changeRange($event)"
    />

    <div class="timer-list">
      <Icon
        name="clock-o"
        class="mr-2"
        :color="isHoliday('tuesday') ? 'red' : ''"
      />
      <span class="mr-2">{{
        $t("label.widget.modal.department.opening_hours.weekdays.tuesday")
      }}</span>
      <OpeningHoursLabel
        :openingHours="openingHours.tuesday"
        v-if="hasRanges('tuesday')"
        id="tuesday"
      />
      <!-- <FullDayLabel :openingHours="openingHours.tuesday" /> -->
      <DayClosedLabel v-if="isHoliday('tuesday')" />
    </div>

    <ElessarSlider
      id="tuesday"
      :values="openingHours.tuesday"
      @changeRange="changeRange($event)"
    />

    <div class="timer-list">
      <Icon
        name="clock-o"
        class="mr-2"
        :color="isHoliday('wednesday') ? 'red' : ''"
      />
      <span class="mr-2">{{
        $t("label.widget.modal.department.opening_hours.weekdays.wednesday")
      }}</span>
      <OpeningHoursLabel
        :openingHours="openingHours.wednesday"
        v-if="hasRanges('wednesday')"
        id="wednesday"
      />
      <!-- <FullDayLabel :openingHours="openingHours.wednesday" /> -->
      <DayClosedLabel v-if="isHoliday('wednesday')" />
    </div>

    <ElessarSlider
      id="wednesday"
      :values="openingHours.wednesday"
      @changeRange="changeRange($event)"
    />

    <div class="timer-list">
      <Icon
        name="clock-o"
        class="mr-2"
        :color="isHoliday('thursday') ? 'red' : ''"
      />
      <span class="mr-2">{{
        $t("label.widget.modal.department.opening_hours.weekdays.thursday")
      }}</span>
      <OpeningHoursLabel
        :openingHours="openingHours.thursday"
        v-if="hasRanges('thursday')"
        id="thursday"
      />
      <!-- <FullDayLabel :openingHours="openingHours.thursday" /> -->
      <DayClosedLabel v-if="isHoliday('thursday')" />
    </div>

    <ElessarSlider
      id="thursday"
      :values="openingHours.thursday"
      @changeRange="changeRange($event)"
    />

    <div class="timer-list">
      <Icon
        name="clock-o"
        class="mr-2"
        :color="isHoliday('friday') ? 'red' : ''"
      />
      <span class="mr-2">{{
        $t("label.widget.modal.department.opening_hours.weekdays.friday")
      }}</span>
      <OpeningHoursLabel
        :openingHours="openingHours.friday"
        v-if="hasRanges('friday')"
        id="friday"
      />
      <!-- <FullDayLabel :openingHours="openingHours.friday" /> -->
      <DayClosedLabel v-if="isHoliday('friday')" />
    </div>

    <ElessarSlider
      id="friday"
      :values="openingHours.friday"
      @changeRange="changeRange($event)"
    />

    <div class="timer-list">
      <Icon
        name="clock-o"
        class="mr-2"
        :color="isHoliday('saturday') ? 'red' : ''"
      />
      <span class="mr-2">{{
        $t("label.widget.modal.department.opening_hours.weekdays.saturday")
      }}</span>
      <OpeningHoursLabel
        :openingHours="openingHours.saturday"
        v-if="hasRanges('saturday')"
        id="saturday"
      />
      <!-- <FullDayLabel :openingHours="openingHours.saturday" /> -->
      <DayClosedLabel v-if="isHoliday('saturday')" />
    </div>

    <ElessarSlider
      id="saturday"
      :values="openingHours.saturday"
      @changeRange="changeRange($event)"
    />

    <div class="timer-list">
      <Icon
        name="clock-o"
        class="mr-2"
        :color="isHoliday('sunday') ? 'red' : ''"
      />
      <span class="mr-2">{{
        $t("label.widget.modal.department.opening_hours.weekdays.sunday")
      }}</span>
      <OpeningHoursLabel
        :openingHours="openingHours.sunday"
        v-if="hasRanges('sunday')"
        id="sunday"
      />
      <!-- <FullDayLabel :openingHours="openingHours.sunday" /> -->
      <DayClosedLabel v-if="isHoliday('sunday')" />
    </div>

    <ElessarSlider
      id="sunday"
      :values="openingHours.sunday"
      @changeRange="changeRange($event)"
    />
  </section>
</template>

<script>
import { ref, provide, watchEffect, watch } from "vue";
import ElessarSlider from "@/modules/widget/components/home/contacts-section/elessar-slider.vue";
import FullDayLabel from "@/modules/widget/components/home/contacts-section/FullDayLabel.vue";
import DayClosedLabel from "@/modules/widget/components/home/contacts-section/DayClosedLabel.vue";
import OpeningHoursLabel from "@/modules/widget/components/home/contacts-section/OpeningHoursLabel.vue";

export default {
  name: "OpeningHours",

  components: {
    ElessarSlider,
    FullDayLabel,
    DayClosedLabel,
    OpeningHoursLabel,
  },

  props: {
    openingHours: {
      type: [Object, Array],
      required: true,
    },
  },

  setup(props, { emit }) {
    const deletedRangeIndex = ref(null);

    const changeRange = ({ day, ranges }) => {
      console.log(day, ranges);
      emit("changeRange", { day, ranges });
    };

    const deleteRange = async ({ day, index }) => {
      await setDeleteRange(index);
      const newRange = props.openingHours[day].filter((day, i) => i !== index);
      emit("changeRange", { day, ranges: newRange });
    };

    const setDeleteRange = (index) => {
      deletedRangeIndex.value = index;
      return Promise.resolve();
    };

    const isHoliday = (day) => {
      return !props?.openingHours?.[day]?.length;
    };

    const hasRanges = (day) => {
      if (props?.openingHours?.[day]?.length)
        return props?.openingHours?.[day]?.length;

      return false;
    };

    provide("deleteRange", deleteRange);
    provide("deletedRangeIndex", deletedRangeIndex);
    provide("setDeleteRange", setDeleteRange);

    return {
      deletedRangeIndex,
      isHoliday,
      hasRanges,
      changeRange,
      deleteRange,
      setDeleteRange,
    };
  },
};
</script>

<style>
</style>