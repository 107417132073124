<template>
  <!-- Templates table -->
  <section class="w-full h-full">
    <PageHeader
      :heading="$t('xpbx.pages.heading.timezones')"
      :showButton="false"
    />

    <!-- Templates table -->
    <div class="card relative table-wrapper">
      <!-- Table -->
      <DataTable
        ref="dt"
        selectionMode="single"
        dataKey="id"
        :paginator="true"
        :rows="10"
        scrollable
        :scrollHeight="`${scrollHeight}px`"
        v-model:selection="selectedRecords"
        v-model:filters="filters"
        :value="timezones"
        filterDisplay="menu"
        :rowClass="rowClass"
        paginatorPosition="top"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} timezones"
        :rowsPerPageOptions="[5, 10, 25]"
        v-if="timezones && timezones.length"
        @rowSelect="onRowSelect"
        @rowUnselect="onRowUnselect"
        :globalFilterFields="['description']"
      >
        <template #header>
          <div class="flex gap-2 align-items-center justify-between">
            <div>
              <Button
                label="New"
                icon="pi pi-plus"
                severity="success"
                class="mr-2 add-record-button"
                @click="openNew"
              />
              <Button
                label="Delete"
                icon="pi pi-trash"
                severity="danger"
                class="delete-button"
                @click="confirmDeleteSelected"
                :disabled="!selectedRecord?.id"
              />
            </div>
            <div class="flex gap-2 items-center">
              <IconField iconPosition="left">
                <InputIcon>
                  <i class="pi pi-search" />
                </InputIcon>
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Search..."
                />
              </IconField>
              <Export
                :dt="dt"
                :tableData="mapedTimezones"
                :columns="timezonesHeaders"
              />
            </div>
          </div>
        </template>
        <Column
          sortable
          field="description"
          :header="$t('xpbx.table-field.description')"
        >
          <template #body="{ data }">
            {{ data?.description }}
          </template>
        </Column>
        <Column
          sortable
          field="priority"
          :header="$t('xpbx.table-field.priority')"
        >
          <template #body="{ data }">
            {{ data?.priority }}
          </template>
        </Column>
      </DataTable>
      <Loader v-else />
    </div>

    <!-- Dialogs -->
    <Dialog
      v-model:visible="deleteRecordsDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
      class="p-fluid relative"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="selectedRecord?.description">{{
          $t("notify.xpbx.message.confirm_delete", {
            delete: `timezone ${selectedRecord?.description}`,
          })
        }}</span>
      </div>
      <template #footer>
        <Button
          label="No"
          icon="pi pi-times"
          text
          @click="deleteRecordsDialog = false"
        />
        <Button
          label="Yes"
          icon="pi pi-check"
          text
          @click="deleteSelectedRecords"
        />
      </template>
    </Dialog>

    <!-- Create timezone -->
    <Dialog
      v-model:visible="templateCreateDialog"
      :style="{ width: '700px' }"
      :header="$t('xpbx.pages.heading.create_timezone')"
      :modal="true"
      :dismissableMask="true"
      class="p-fluid relative custom-dialog-heading"
    >
      <div>
        <div class="field mb-1">
          <label for="description">{{ $t("xpbx.labels.description") }}</label>
          <InputText
            id="description"
            type="text"
            v-model.trim="timezone.description"
            required="true"
            autofocus
            :class="{ 'p-invalid': submitted && !timezone.description }"
          />
          <small
            class="p-error"
            v-if="submitted && !!errors?.description?.[0]"
            >{{ errors?.description?.[0] }}</small
          >
        </div>
        <QueueHint :title="$t('xpbx.hints.timezones.description')" />

        <div class="field mb-1">
          <label for="is_active">{{ $t("xpbx.labels.is_active") }}</label>
          <div class="w-40">
            <form-select
              v-model="timezone.is_active"
              :options="activeOptions"
              name="channel"
              id="is_active"
              :activeClass="true"
            />
          </div>
        </div>
        <QueueHint :title="$t('xpbx.hints.timezones.is_active')" />

        <div class="field mb-1">
          <label for="priority">{{ $t("xpbx.labels.priority") }}</label>
          <div class="w-20">
            <form-select
              v-model="timezone.priority"
              :options="priorityOptions"
              name="channel"
              id="priority"
              :activeClass="true"
            />
          </div>
        </div>
        <QueueHint :title="$t('xpbx.hints.timezones.priority')" />
      </div>
      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          text
          @click="templateCreateDialog = false"
        />
        <Button label="Save" icon="pi pi-check" text @click="create" />
      </template>
    </Dialog>
    <!-- Edit timezone -->
    <Dialog
      v-model:visible="templateDialog"
      :style="{ width: '700px' }"
      :header="$t('xpbx.pages.heading.edit_timezone')"
      :modal="true"
      :dismissableMask="true"
      class="p-fluid relative custom-dialog-heading"
    >
      <div class="flex items-center py-2 gap-2 mb-2">
        <p
          class="w-1/2 cursor-pointer pb-1"
          @click="activeTab = 1"
          :class="{ 'active-tab': activeTab === 1 }"
        >
          {{ $t("xpbx.pages.heading.edit_timezone") }}
        </p>
        <p
          class="w-1/2 cursor-pointer pb-1"
          @click="activeTab = 2"
          :class="{ 'active-tab': activeTab === 2 }"
        >
          {{ $t("xpbx.pages.heading.edit_opening_hours") }}
        </p>
      </div>
      <div v-if="activeTab === 1">
        <div class="field mb-1">
          <label for="description">{{ $t("xpbx.labels.description") }}</label>
          <InputText
            id="description"
            type="text"
            v-model.trim="timezone.description"
            required="true"
            autofocus
            :class="{ 'p-invalid': submitted && !timezone.description }"
          />
          <small
            class="p-error"
            v-if="submitted && !!errors?.description?.[0]"
            >{{ errors?.description?.[0] }}</small
          >
        </div>
        <QueueHint :title="$t('xpbx.hints.timezones.description')" />

        <div class="field mb-1">
          <label for="password">{{ $t("xpbx.labels.is_active") }}</label>
          <div class="w-40">
            <form-select
              v-model="timezone.is_active"
              :options="activeOptions"
              name="channel"
              id="channel"
              :activeClass="true"
            />
          </div>
        </div>
        <QueueHint :title="$t('xpbx.hints.timezones.is_active')" />

        <div class="field mb-1">
          <label for="password">{{ $t("xpbx.labels.priority") }}</label>
          <div class="w-20">
            <form-select
              v-model="timezone.priority"
              :options="priorityOptions"
              name="channel"
              id="channel"
              :activeClass="true"
            />
          </div>
        </div>
        <QueueHint :title="$t('xpbx.hints.timezones.priority')" />
      </div>
      <div v-if="activeTab === 2">
        <OpeningHours
          :openingHours="openingHours"
          v-if="openingHours?.monday"
          @changeRange="changeRange"
        />
        <!-- <AddOpeningHours
          v-else
          :openingHours="openingHours"
          @changeRange="changeRange"
        /> -->
      </div>

      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          text
          @click="templateDialog = false"
        />
        <Button label="Save" icon="pi pi-check" text @click="updateHandler" />
      </template>
    </Dialog>
  </section>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import PageHeader from "@/modules/xpbx/components/home/UI/PageHeader.vue";
import TableSettings from "@/modules/xpbx/components/UI/table-settings";
import useTimezones from "@/modules/xpbx/composables/useTimezones";
import StatusIcon from "@/modules/xpbx/components/UI/status-icon/index.vue";
import Loader from "@/modules/xpbx/components/UI/loader/index.vue";
import OpeningHours from "@/modules/xpbx/pages/settings/timezones/components/OpeningHours";
import AddOpeningHours from "@/modules/xpbx/pages/settings/timezones/components/AddOpeningHours";
// Datatable
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import Textarea from "primevue/textarea";
import { FilterMatchMode } from "primevue/api";
import validator from "@/composables/auth/validator";
import Export from "@/modules/xpbx/pages/settings/components/export/index.vue";
import { validateTimezone } from "@/composables/auth/templateValidations";
import { timezonesHeaders } from "@/modules/xpbx/pages/settings/components/table-headers/data.js";
import QueueHint from "@/modules/xpbx/pages/settings/queue-detail/components/queue-hint/index.vue";

export default {
  name: "TimezonesTable",

  components: {
    PageHeader,
    TableSettings,
    StatusIcon,
    Loader,
    DataTable,
    Column,
    Button,
    InputText,
    Export,
    Dialog,
    IconField,
    InputIcon,
    Textarea,
    OpeningHours,
    AddOpeningHours,
    QueueHint,
  },

  setup() {
    const scrollHeight = ref(window.innerHeight - 290);
    // Datatable
    const dt = ref();
    const activeTab = ref(1);
    const submitted = ref(false);
    const { errors } = validator();
    const templateDialog = ref(false);
    const templateCreateDialog = ref(false);
    const selectedRecord = ref(null);
    const selectedRecords = ref([]);
    const deleteRecordsDialog = ref(false);
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const mapedTimezones = computed(() => {
      return timezones.value.map((timezone) => {
        return {
          ...timezone,
          is_active: timezone.is_active ? "Active" : "Inactive",
        };
      });
    });

    const {
      findTimezones,
      findTimezone,
      createTimezone,
      updateTimezone,
      deleteTimezone,
      updateTimezoneData,
      openingHours,
      timezone,
      timezones,
    } = useTimezones();

    const onRowSelect = (event) => {
      selectedRecord.value = event.data;
    };

    const onRowUnselect = async (event) => {
      if (selectedRecord.value?.id === event.data.id) {
        await findTimezone(event.data.id);
        timezone.value = event?.data;
        templateDialog.value = true;
      }
    };

    // Data Table Methods
    const openNew = () => {
      timezone.value = {
        description: "",
        priority: 1,
        is_active: 1,
      };

      templateCreateDialog.value = true;
    };

    const reset = () => {
      timezone.value = {
        description: "",
        priority: 1,
        is_active: 1,
      };
      activeTab.value = 1;
      submitted.value = false;
      selectedRecord.value = null;
    };

    const closeDialog = (value) => {
      templateDialog.value = value;
    };

    const updateOpeningHours = async () => {
      await updateTimezoneData(openingHours.value, selectedRecord.value?.id);
    };

    const update = async (data) => {
      const isValid = validateTimezone(timezone.value);
      if (!isValid) return;

      await updateTimezone(timezone.value, selectedRecord.value?.id);
    };

    const create = async () => {
      submitted.value = true;

      const isValid = validateTimezone(timezone.value);
      if (!isValid) return;

      // Send the data to the server
      await createTimezone(timezone.value);

      templateCreateDialog.value = false;
    };

    const updateHandler = async () => {
      submitted.value = true;

      if (activeTab.value === 1) {
        await update();
      } else {
        await updateOpeningHours();
      }

      templateDialog.value = false;
    };

    const confirmDeleteSelected = () => {
      deleteRecordsDialog.value = true;
    };

    const deleteSelectedRecords = async () => {
      await deleteTimezone(selectedRecords.value.id);
      deleteRecordsDialog.value = false;
    };

    const changeRange = ({ day, ranges }) => {
      openingHours.value[day] = ranges;
    };

    const rowClass = (data) => {
      return [
        {
          "table-active": data.is_active === 1,
          "table-inactive": data.is_active === 0,
          "table-selected": timezone.value?.id === data?.id,
        },
      ];
    };

    const activeOptions = [
      { name: "active", value: 1 },
      { name: "inactive", value: 0 },
    ];

    const priorityOptions = [
      { name: 0, value: 0 },
      { name: 1, value: 1 },
      { name: 2, value: 2 },
      { name: 3, value: 3 },
      { name: 4, value: 4 },
      { name: 5, value: 5 },
      { name: 6, value: 6 },
      { name: 7, value: 7 },
      { name: 8, value: 8 },
      { name: 9, value: 9 },
      { name: 10, value: 10 },
    ];

    const checkTableClick = () => {
      const appContainer = document.querySelector(".app-container");

      appContainer.addEventListener("click", (e) => {
        const classLength = e?.target?.classList?.length;
        const includeButton = e?.target?.classList?.contains("p-button-label");
        if (classLength > 0 && selectedRecord?.value?.id && !includeButton)
          selectedRecord.value = null;
      });
    };

    watch(templateDialog, (value) => {
      if (!value) reset();
    });

    watch(templateCreateDialog, (value) => {
      if (!value) reset();
    });

    onMounted(async () => {
      checkTableClick();
      await findTimezones();
    });

    return {
      dt,
      filters,
      errors,
      // Should be deleted
      // End
      timezone,
      openingHours,
      activeTab,
      timezones,
      findTimezone,
      activeOptions,
      priorityOptions,
      templateDialog,
      templateCreateDialog,
      timezonesHeaders,
      mapedTimezones,
      rowClass,
      submitted,
      scrollHeight,
      selectedRecord,
      selectedRecords,
      deleteRecordsDialog,
      create,
      updateHandler,
      reset,
      openNew,
      closeDialog,
      changeRange,
      onRowSelect,
      onRowUnselect,
      confirmDeleteSelected,
      deleteSelectedRecords,
      updateTimezone,
      deleteTimezone,
      updateTimezoneData,
    };
  },
};
</script>


<style lang="scss" scoped>
.p-button.add-record-button {
  background: rgba(#32bcad, 0.8);
  border: 1px solid rgba(#32bcad, 0.8);

  &:hover {
    background: rgba(#32bcad, 1);
    border: 1px solid #32bcad;
  }
}

.p-button.delete-button {
  background: #f05a94;
  border: 1px solid #f05a94;

  &:hover {
    background: #f05a94;
  }
}

.p-button.delete-button.p-disabled {
  background: rgba(#f05a94, 0.5);
  border: 1px solid rgba(#f05a94, 0.5);
  border-color: rgba(#f05a94, 0.5);

  &:hover {
    background: rgba(#f05a94, 0.8);
    border-color: rgba(#f05a94, 0.8);
  }
}

.active-tab {
  color: #32bcad;
  border-bottom: 2px solid #32bcad;
}
</style>